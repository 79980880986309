






import Vue from 'vue'
export default Vue.extend({
  name: 'QuickNav',
  props: {
    bottom: {
      type: Number,
      required: false,
      default: 50,
    },
  },
  data() {
    return {
      destination: 100000,
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      const quickNav = document.querySelector('#quickNav')

      if (window.scrollY > 100) {
        this.destination = 0
        quickNav?.classList.add('rotate')
      } else {
        this.destination = 100000
        quickNav?.classList.remove('rotate')
      }
    })
  },
  methods: {
    click() {
      window.scrollTo({ top: this.destination, behavior: 'smooth' })
    },
  },
})
